export enum IkeaNextApiError {
  invalidSerial,
  timeOut,
  unknown,
  reprint,
  differentCode,
}

export interface ApiResponse {
  errorType?: IkeaNextApiError;
  errorMessage?: string;
  success: boolean;
}



export class IkeaNextApi {
  public cnt : number;
  protected apiSlc = 'https://api1.sl-concept.pl/print';
  constructor() {
    this.cnt = 0;
  }


  private controller = new AbortController();
  private timeoutId = setTimeout(() => this.controller.abort(), 15000);


  private getFetcher = (url: string): Promise<any> => fetch(url,{
    signal: this.controller.signal,
    referrer: "",
  }).then((res) => res.json());


  public async createPallet(serials : string[]) : Promise<ApiResponse> {
    ++this.cnt;

    try {

      let serialList: string = '';

      serials.map(async (serial) => {
        serialList+=`/${serial}`;
      });

      const nextApiLink = `http://nextpoland.elica.intra/isw/webapp/elica/next/iaf/ask/SSCC/newSSCC/newrec(jsrec,51,${serials.length},codlot)/NULL/0${serialList}?_ag=SSCC&_req=newSSCC&_cnt=${this.cnt}`;

    //  console.log(nextApiLink);
      const data = await this.getFetcher(nextApiLink);
      clearTimeout(this.timeoutId);

      if(data) {
        if (data[0].error) {
          throw new Error(data[0].error);
        }

        if(data[0].codlotp) {
          //TUTAJ WYSYLAMY PALETE DO API, ABY ZOSTALA ZAPISANA I SPRAWDZAMY CZY ILOSC DLA KODU SIE ZGADZA!


          //success: https://mocki.io/v1/0d3d7f27-fadd-445b-9eed-04626815597b
            //zdublowana etykieta https://mocki.io/v1/0bd53267-2109-4645-ba51-2adb4f906f3f


          //zmienić na post!
          const slcResponse = await fetch(`${this.apiSlc}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data[0]),
          });

          const slcJson: ApiResponse = await slcResponse.json();

          console.log(slcJson);

          return slcJson;



          /*
          * gdy ok {success: true}
          *
          * */



        }

      }
      throw new Error(`Nieznany błąd, data nie istnieje.`);

    } catch (e: any) {
      if(e.message === 'Failed to execute \'fetch\' on \'Window\': The user aborted a request.' || e.message === 'The user aborted a request.') {
        console.log('TIMEOUT');

        // wyslanie do api informacji o timeout
        //zmienić na post!
        await fetch(`${this.apiSlc}/error`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
           body: JSON.stringify({serials: serials, pallet_create: 1, error: 'Time Out!'}),
        });

        return {
          errorMessage: 'Time Out!',
          errorType: IkeaNextApiError.timeOut,
          success: false,
        }





      } else if(e.message.includes('Lotti non coerenti dello stesso prodotto')) {
        console.log('Różne kody lub partie produktu!');
        console.log(e.message);

        return {
          errorMessage: 'Różne kody lub partie produktu!',
          errorType: IkeaNextApiError.differentCode,
          success: false,
        }

      } else {
        return {
          errorMessage: e.message,
          errorType: IkeaNextApiError.unknown,
          success: false,
        }
      }



    }

  }


  public async printPallet(serial: string): Promise<ApiResponse> {
    ++this.cnt;

    try {
      //error link https://mocki.io/v1/6e164849-8bb0-42bc-a798-f7d6dba78bde
      //unknown error link https://mocki.io/v1/d05e6612-6850-4fa4-b081-d148bb0eb1f0
      //ok link https://mocki.io/v1/98267833-7287-420b-9fa2-9b511e7e1ef6

      const nextApiLink = `http://nextpoland.elica.intra/isw/webapp/elica/next/iaf/ask/SSCC/printSSCC/newrec(jsrec,51,1,codlot)/NULL/0/${serial}?_ag=SSCC&_req=printSSCC&_cnt=${this.cnt}`;

      const data = await this.getFetcher(nextApiLink);
      clearTimeout(this.timeoutId);

      if(data) {
        if(data[0].error) {
          throw new Error(data[0].error);
        }

        if(data[0].codlotp) {
          //TUTAJ WYSYLAMY PALETE DO API, ABY ZOSTALA ZAPISANA I SPRAWDZAMY CZY ILOSC DLA KODU SIE ZGADZA!


          //success: https://mocki.io/v1/0d3d7f27-fadd-445b-9eed-04626815597b
          //zdublowana etykieta https://mocki.io/v1/0bd53267-2109-4645-ba51-2adb4f906f3f


          //zmienić na post!
          const slcResponse = await fetch(`${this.apiSlc}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data[0]),
          });

          const slcJson: ApiResponse = await slcResponse.json();

          console.log(slcJson);

          return slcJson;



          /*
          * gdy ok {success: true}
          *
          * */



        }



      }

      //http://172.24.128.248/isw/webapp/elica/next/main/?user=prod&usejq2=2&mobile=1&header=0&lang=pl#ikea-pallet-print




      throw new Error(`Nieznany błąd, data nie istnieje.`);


    } catch(e: any) {
      console.log(e.message);

      if(e.message === 'Failed to execute \'fetch\' on \'Window\': The user aborted a request.' || e.message === 'The user aborted a request.') {
        console.log('TIMEOUT');

        await fetch(`${this.apiSlc}/error`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({serials: [serial], pallet_create: 0, error: 'Time Out!'}),
        });
        // wyslanie do api informacji o timeout

        return {
          errorMessage: 'Time Out!',
          errorType: IkeaNextApiError.timeOut,
          success: false,
        }

      } else if(e.message.includes('non presente o SSCC vuoto')) {
        console.log('Niepoprawny numer seryjny');
        console.log(e.message);

        return {
          errorMessage: e.message,
          errorType: IkeaNextApiError.invalidSerial,
          success: false,
        }

      } else {
        ///Wyslanie informacji do API o nieznanym bledzie
        return {
          errorMessage: e.message,
          errorType: IkeaNextApiError.unknown,
          success: false,
        }

      }

    }


  }


}
