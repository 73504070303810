import React, { useState } from 'react';
import { Button, Space } from 'antd';
import './App.css';
import { PrintPallet } from './components/PrintPallet/PrintPallet';
import { CreatePallet } from './components/CreatePallet/CreatePallet';

export const App = () => {

  enum Module {
    // Drukowanie palet Ikea
    printPallet,
    // Tworzenie nowej palety Ikea
    createPallet,
  }


  const[module, setModule] = useState<Module>(Module.printPallet)



 return  (
    <div className="App">

      {
        module === Module.printPallet ? <PrintPallet/> : module === Module.createPallet && <CreatePallet/>
      }

      <Space/>
      <div className={'div-button'}>
        <Button className='router-button' type={module===Module.printPallet && 'primary' as any} onClick={() => setModule(Module.printPallet)}>Drukowanie Etykiety</Button>
        <Button className='router-button' type={module===Module.createPallet && 'primary' as any} onClick={() => setModule(Module.createPallet)}>Tworzenie Etykiety</Button>
      </div>

    </div>
  );
}


