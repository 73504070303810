import React, { FormEvent, useState } from 'react';
import { Button, Form, Input, message, Typography } from 'antd';
import useSound from 'use-sound';
import { IkeaNextApi, IkeaNextApiError } from '../../utils/ikeaNextApi';
import { LoadingOutlined, SendOutlined } from '@ant-design/icons';

import './CreatePallet.css';
import { ButtonSerial } from '../../../src/components/CreatePallet/ButtonSerial';

interface Props {

}

export const CreatePallet = (props: Props) => {

  const [inputSerial, setInputSerial] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [serials, setSerials] = useState<string[]>([]);

  const ikeaApi = new IkeaNextApi();

  const suffix = (
    !loading ? <SendOutlined className={'icon-submit'}/>
      :
      <LoadingOutlined className={'icon-submit'}/>
  );

  const [playSuccess] = useSound(
    '/sounds/success.mp3',
    {volume: 1},
  );

  const [playError01] = useSound(
    '/sounds/error01.mp3',
    {volume: 1},
  );

  const [playError02] = useSound(
    '/sounds/error02.mp3',
    {volume: 1},
  );

  const onSubmitForm = async (e: FormEvent) => {
    const pattern = /^\d{10}$/;

    try {

      if (!pattern.test(inputSerial)) {
        playError01();
        message.error('Niepoprawny numer seryjny!');
        return;
      }


      if(serials.includes(inputSerial)) {
        playError01();
        message.error('Ten numer seryjny został już zeskanowany!');
        return;
      }


    playSuccess();
    setSerials([...serials,inputSerial]);


    } catch (e) {
      console.log(e);
    } finally {
      setInputSerial('');
    }


  };


  const createPal = async () => {
    try{
      setLoading(true);
      const resp = await ikeaApi.createPallet(serials);

      if(resp.success) {
        playSuccess();
        message.success('Drukowanie!',0.5);
      } else {

        if(resp.errorType===IkeaNextApiError.differentCode) {
          playError02();
          message.error(resp.errorMessage);
        }

        if(resp.errorType===IkeaNextApiError.timeOut) {
          playError01();
          message.error('TIMEOUT!...');
        }

        if(resp.errorType===IkeaNextApiError.unknown) {
          playError01();
          message.error(`Nieznany błąd: "${resp.errorMessage}"`);
        }


      }

    } catch (e) {

    } finally {
      setLoading(false);
      setSerials([]);
    }



  }


  const removeSerial = (serial: string) => {
    setSerials((arr) => {
      return arr.filter((s) => s !== serial)
    });
  }


  return (
    <>

    <Form onSubmitCapture={onSubmitForm}>
      <Input className="input-serial" value={inputSerial} onChange={(event) => setInputSerial(event.target.value)}
             placeholder="Numer Seryjny 1..." suffix={suffix} autoFocus={true}/>
      <Typography.Text style={{float: 'right'}}>Ilość zeskanowana: {serials.length}</Typography.Text>
    </Form>


      <div className={'SerialDiv'}>

        {serials.map(serial => <ButtonSerial removeHandler={removeSerial} key={serial} serial={serial}/>)}

      </div>




      <div className={'create-button-div'}>
        <Button onClick={createPal} style={{width: '50%', height: '50px'}} disabled={serials.length === 0 || loading}>{loading ?  <LoadingOutlined/> : 'Utwórz Paletę'}</Button>
      </div>

    </>
  );
};
