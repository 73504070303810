import React, { FormEvent, useState } from 'react';
import { LoadingOutlined, SendOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Input, message } from 'antd';
import { IkeaNextApi, IkeaNextApiError } from '../../utils/ikeaNextApi';
import useSound from 'use-sound';


interface Props {

}

export const PrintPallet = (props: Props) => {
  const [inputSerial, setInputSerial] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [reprint, setReprint] = useState<boolean>(false);
  const [reprintError, setReprintError] = useState<string>('');

  const ikeaApi = new IkeaNextApi();

  const suffix = (
    !loading ? <SendOutlined className={'icon-submit'} />
      :
      <LoadingOutlined className={'icon-submit'} />
  );

  const [playSuccess] = useSound(
    '/sounds/success.mp3',
    { volume: 1 }
  );

  const [playError01] = useSound(
    '/sounds/error01.mp3',
    { volume: 1 }
  );

  const [playError02] = useSound(
    '/sounds/error02.mp3',
    { volume: 1 }
  );



  const closeError = () => {
    setReprint(false);
    setReprintError('');
  }


  const onSubmitForm = async (e: FormEvent) => {
    const pattern = /^\d{10}$/;
    setLoading(true);

    try {

      if(!pattern.test(inputSerial)) {
        playError01();
        message.error('Niepoprawny numer seryjny!');
        return;
      }

      const serial = inputSerial;
      setInputSerial('');
      const resp = await ikeaApi.printPallet(serial);


      if(resp.success) {
        playSuccess();
        message.success('Drukowanie!',0.5);

      } else {

        if(resp.errorType===IkeaNextApiError.timeOut) {
          playError01();
          message.error('TIMEOUT!...');
        }

        if(resp.errorType===IkeaNextApiError.invalidSerial) {
          playError01();
          message.error(resp.errorMessage);
        }

        if(resp.errorType===IkeaNextApiError.unknown) {
          playError01();
          message.error(`Nieznany błąd: "${resp.errorMessage}"`);
        }

        if(resp.errorType===IkeaNextApiError.reprint) {
          playError02();
          setReprintError(resp.errorMessage as string);
          setReprint(true);
        }

      }

    } catch (e) {
      console.log(e);
    } finally {
      setInputSerial('');
      setLoading(false);
    }



  }

  return (

    reprint ?
      <>
      <Alert
        message="UWAGA! ZDUBLOWANA ETYKIETA!!"
        description={<><p style={{fontWeight: 'bold'}}>{reprintError}</p><Button onClick={closeError} style={{color: 'red',}}>Zamknij ostrzeżenie</Button></>}
        type="error"
        showIcon
      />
      </>
      :
      <Form onSubmitCapture={onSubmitForm}>
      <Input className="input-serial" value={inputSerial} onChange={(event) => setInputSerial(event.target.value)} placeholder="Numer Seryjny 1..." suffix={suffix} autoFocus={true} />
    </Form>

  )


}
