import React from 'react';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

interface Props {
  serial: string;
  removeHandler: (serial: string) => void;
}

export const ButtonSerial = (props: Props) => {
  return <Button size="small" onClick={() => props.removeHandler(props.serial)} danger={true} style={{margin: '5px',}} type={'dashed'} icon={<CloseOutlined style={{fontSize: '12px'}} />}>{props.serial}</Button>
}
